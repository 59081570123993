import React from "react";
import PropTypes from "prop-types";
// import classNames from "classnames";

// import { List, ListItem } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles"

// import styles from "../../assets/jss/footerStyle.js";

import cbrand from "../../assets/img/netmin.gif";
import bmvi from "../../assets/img/BMV_DE.png";
import footerbg from "../../assets/img/s_footer_bg.jpg";

// const useStyles = makeStyles(styles);

export default function Footer(props) {
    // const classes = useStyles();
    // const { whiteFont } = props;
    // const footerClasses = classNames({
    //     [classes.footer]: true,
    //     [classes.footerWhiteFont]: whiteFont
    // });
    // const aClasses = classNames({
    //     [classes.a]: true,
    //     [classes.footerWhiteFont]: whiteFont
    // });
    return(
        <footer>
            <div className="footer-top footer-bg s-footer-bg" style={{backgroundImage: "url(" + footerbg + ")"}}>
                <div className="newsletter-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="newsletter-wrap">
                                    <div className="section-title newsletter-title">
                                        <h2>Unser <span>Newsletter</span></h2>
                                    </div>
                                    <div className="newsletter-form">
                                        <form action="#">
                                            <div className="newsletter-form-grp">
                                                <i className="far fa-envelope"></i>
                                                <input type="email" placeholder="E-Mail eingeben..."/>
                                            </div>
                                            <button>SUBSCRIBE <i className="fas fa-paper-plane"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="footer-widget mb-50">
                                <div className="footer-logo mb-35">
                                    <a href="http://netmingames.de/" target="_blank" rel="noreferrer noopener">
                                        <img src={cbrand} alt="Netmin Games" />
                                    </a>
                                </div>
                                <div className="footer-text">
                                    <p>Spiele die Spaß machen - seit 2001</p>
                                    <div className="footer-contact">
                                        <ul>
                                            <li>
                                                <i className="fas fa-map-marker-alt"></i>
                                                <span>Anschrift : </span>
                                                Philipp-Reis-Str. 6 - 55129 Mainz -  Rheinland-Pfalz -  Deutschland
                                            </li>
                                            <li>
                                                <i className="fas fa-headphones"></i>
                                                <span>Telefon : </span>
                                                +49 6131 507 896
                                            </li>
                                            <li>
                                                <i className="fas fa-envelope-open"></i>
                                                <span>E-Mail : </span>
                                                info@handball-manager.com
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-6">
                            <div className="footer-widget mb-50">
                                <div className="fw-title mb-35">
                                    <h5>Produkte</h5>
                                </div>
                                <div className="fw-link">
                                    <ul>
                                        <li>
                                            <a href="http://handball-manager.com/old/index.htm" target="_blank" rel="noopener noreferrer">Vorgängerversionen</a>
                                        </li>
                                        <li>
                                            <a href="https://store.steampowered.com/app/1014050/Hockey_Manager_2020/" target="_blank" rel="noopener noreferrer">Eishockey Manager</a>
                                        </li>
                                        <li>
                                            <a href="http://www.passage4.de/" target="_blank" rel="noopener noreferrer">Passage 4</a>
                                        </li>
                                        <li>
                                            <a href="https://store.steampowered.com/app/273830/Aviator__Bush_Pilot/" target="_blank" rel="noopener noreferrer">Busch Pilot</a>
                                        </li>
                                        <li>
                                            <a href="http://www.handballaction.de/#videosprung" target="_blank" rel="noopener noreferrer">Handball Action</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-6">
                            <div className="footer-widget mb-50">
                                <div className="fw-title mb-35">
                                    <h5>Hilfe benötigt?</h5>
                                </div>
                                <div className="fw-link">
                                    <ul>
                                        <li>
                                            <a href="/imprint">Impressum</a>
                                        </li>
                                        <li>
                                            <a href="/privacy">Datenschutzerklärung</a>
                                        </li>
                                        {/* <li>
                                            <a href="#">Refund Policy</a>
                                        </li>
                                        <li>
                                            <a href="#">FAQ</a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="footer-widget mb-50">
                                <div className="fw-title mb-35">
                                    <h5>Follow Us</h5>
                                </div>
                                <div className="footer-social">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/NetminGames" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/netmingames" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/user/netmingames/featured" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-widget mb-50">
                                <img src={bmvi} alt="BMVI" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-wrap s-copyright-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="copyright-text">
                                <p>Copyright © 2020 <a href="https://www.darainnovations.de" target="_blank" rel="noreferrer noopener">DaRa Innovations GmbH</a> - All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 d-none d-md-block">

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    whiteFont: PropTypes.bool
}