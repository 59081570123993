import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "./assets/css/default.css";
import "bootstrap/dist/css/bootstrap.css";

import LandingPage from "./views/LandingPage/LandingPage.js";
import Imprint from "./views/Imprint.js";
import PrivacyPolicy from "./views/privacy.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/imprint" component={Imprint} />
      <Route path="/privacy" component={PrivacyPolicy} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById('root')
);
