import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header/Header.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Lander from "../../components/Parallax/Lander.js";
// import TeamSection from "../../views/LandingPage/Sections/TeamSection.js";
import ContentSection from "../../views/LandingPage/Sections/ContentSection.js";
import Footer from "../../components/Footer/Footer.js";

import styles from "../../assets/jss/landingPage.js";

import logo from "../../assets/img/long.png";
import landing_bg from "../../assets/img/pokale.jpg";
import slider01 from "../../assets/img/player1.png";
import slider02 from "../../assets/img/player2.png";
import circle_shape from "../../assets/img/circle_shape.png";
import steam from "../../assets/img/plat-steam-white.svg";

import { useSpring, animated } from "react-spring";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
    const [ resetRotate, setResetRotate ] = React.useState(false);
    const slideInRight = useSpring({
        from: {
            opacity: 0,
            transform: "translateX(-150px)"
        },
        to: {
            opacity: 1,
            transform: "translateX(0)"
        },
        config: {
            duration: 1000
        }
    });
    const slideInLeft = useSpring({
        from: {
            opacity: 0,
            transform: "translateX(150px)"
        },
        to: {
            opacity: 1,
            transform: "translateX(0)"
        },
        config: {
            duration: 1000
        }
    });
    const fadeInUp = useSpring({
        from: {
            opacity: 0,
            transform: "translateY(20px)"
        },
        to: {
            opacity: 1,
            transform: "translateY(0)"
        },
        config: {
            duration: 600
        }
    });

    const rotate = useSpring({
        from: {
            transform: "rotate(0deg)"
        },
        to: {
            transform: "rotate(360deg)"
        },
        config: {
            duration: 50000
        },
        onRest: () => setResetRotate(!resetRotate),
        reset: resetRotate
    });

    const classes = useStyles();
    const {...rest} = props;
    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand={logo}
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 100,
                    color: "white"
                }}
                {...rest}
            />
            <main>
                <section className="hero-area">
                    <Lander filter image={landing_bg}>
                        <div className="container-fluid container-full-padding">
                            <div className="row">
                                <div className="col-xl-6 col-lg-7 col-md-11">
                                    <div className="hero-content">
                                        <animated.h2 style={fadeInUp}><span>Bester</span> Handball Manager</animated.h2>
                                        <animated.p style={fadeInUp}>Spüre den Puls, wenn dein Spieler zum entscheidenden Wurf ansetzt, der dich zur Legende machen wird. 
                                        Es liegt an dir, deinen Lieblingsverein und dich als Manager und Trainer im nationalen internationalen Profihandball unsterblich zu machen.
                                        </animated.p>
                                        <animated.a href="https://store.steampowered.com/app/1262930/Handball_Manager_2021/" className="btn btn-style-one" style={fadeInUp}><img src={steam} alt="Steam" style={{maxWidth: "35px"}}/>  Auf Steam kaufen</animated.a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <animated.div className="slider-img1" style={slideInLeft}>
                            {/* <img src={slider01} alt="Image1" /> */}
                        </animated.div>
                        <animated.div className="slider-img2" style={slideInRight}>
                            {/* <img src={slider02} alt="Image2" /> */}
                        </animated.div>
                        <div className="slider-circle" style={{display: "none"}}>
                            <animated.img src={circle_shape} alt="Circle" style={rotate} />
                        </div>
                    </Lander>
                </section>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    {/* <TeamSection /> */}
                    <ContentSection />
                </div>
            </main>
            <Footer />
        </div>
    );
}