import React from "react";

import hbl from "../../../assets/img/hbl_logo.png";
import hbl2 from "../../../assets/img/2hbl.png";
import asobal from "../../../assets/img/asobal.png";
import strabag from "../../../assets/img/strabag.png";
import s1 from "../../../assets/img/1_german.jpg";
import s2 from "../../../assets/img/3_german.jpg";
import s3 from "../../../assets/img/4_german.jpg";
import s4 from "../../../assets/img/6_german.jpg";
import s5 from "../../../assets/img/7_german.jpg";
import s6 from "../../../assets/img/8_german.jpg";

export default function ContentSection() {
    return (
        <section className="gray-bg pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="game-single-content">
                            <div className="upcoming-game-ahead">
                                <div className="uc-game-head-title">
                                    <h4>Lizenz für die LIQUI MOLY HBL und 2. Handball-Bundesliga sowie die spanische Sacyr ASOBAL Liga und die tschechische STRABAG RAIL Extraliga</h4>
                                </div>
                            </div>
                            <p>Handball ist ein Spiel für harte Kerle, beweise dass du als Manager und Trainer der Härteste unter ihnen bist.<br/><br/>
                                Meistere jede Herausforderung, nutze jede Chance, auch wenn sie noch so klein ist. Messe dich mit den Besten der Welt – <br/>
                                forme deinen Verein und seine Spieler nach deinen Vorstellungen und führe ihn zu ungekanntem Erfolg. <br/><br/>Es liegt an dir: Triff deine Entscheidung … mit allen Konsequenzen.
                            </p>
                            <div className="game-single-img">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
                                        <img src={hbl} alt="LIQUI MOLY Handball-Bundesliga" />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
                                        <img src={hbl2} alt="2. Handball-Bundesliga" />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
                                        <img src={asobal} alt="Sacyr ASOBAL" />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 text-center">
                                        <img src={strabag} alt="STRABAG RAIL Extraliga" />
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="game-single-title mt-60 mb-30">
                                <h4>Unendliche Möglichkeiten</h4>
                            </div>
                            <div className="game-single-info mb-65">
                                <p>
                                Beim Handball Manager 2021 hast du die volle Kontrolle über alle sportlichen und kaufmännischen Aspekte deines Vereins und kannst dich mit Fachwissen und geschickter Planung selbst belohnen, indem du die endlosen Möglichkeiten nutzt:
                                </p>
                                <ul>
                                    <li>
                                    Beginne deine Karriere in deinen Lieblingsverein. Trainiere und manage dein Handball Team von der Amateurliga bis zur Spitze. Greife dabei auf echte Spieler und echte Vereine der LIQUI MOLY Handball-Bundesliga und der 2. Handball-Bundesliga zurück oder erstelle mit dem Editor dein ganz eigenes Team.
                                    </li>
                                    <li>
                                    Nutze den endlosen Funktionsumfang mit Training, Taktik, Transfers, Stadion, Fanbetreuung, Jugendarbeit, Merchandising, Privatleben, Aktienmarkt sowie realen Karrieren, um eine unsterbliche Legende in der Handballwelt zu werden.
                                    </li>
                                    <li>
                                    Leicht zu bedienen durch das intuitive E-Mail-System und die vielen Assistenten    
                                    </li>
                                    <li>
                                    Übernimm die Aufgaben als Nationaltrainer und führe dein Land zu WM-Titeln und Olympiasiegen.
                                    </li>
                                    <li>
                                    Aktiviere den Corona-Modus und erlebe die turbulenten Zeiten aus der Sicht des Managers eines Handballvereins.
                                    </li>
                                    <li>
                                    Erlebe die Spiele in einer aktionsreichen Darstellung live und übernimm die volle Kontrolle über dein eigenes Team – in Echtzeit.
                                    </li>
                                    <li>
                                    Manager im Männer- und Frauen-Handball
                                    </li>
                                    <li>
                                    Tausche deine selbsterstellten Editor- und Communityinhalte mit anderen Spielern über den Steam Workshop. So kannst du das von dir erschaffene Handball Universum mit der ganzen Welt teilen.
                                    </li>
                                </ul>
                            </div>
                            <div className="game-single-title mb-30">
                                <h4>Screenshots</h4>        
                            </div>
                            <div className="game-single-gallery">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <img src={s1} alt=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <img src={s2} alt=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <img src={s3} alt=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <img src={s4} alt=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <img src={s5} alt=""/>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <img src={s6} alt=""/>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}